import React from 'react';
import { MapPin } from 'react-feather';
import { TeamFixture } from 'src/api/getTeamFixtures';
import { CSS_BREAKPOINTS } from 'src/constants';
import { Accordion } from 'src/shared/widgets/Accordion/Accordion';
import { DataTable, DataTableColumnDef } from 'src/shared/widgets/DataTable/DataTable';
import { DataTableItemDialog } from 'src/shared/widgets/DataTable/DataTableItemDialog';
import { formatTime } from 'src/utils/dates';

interface EventMonthAccordionProps {
  month: string;
  fixture: TeamFixture;
  isDefaultOpen?: boolean;
}

const columns: DataTableColumnDef<TeamFixture[number]>[] = [
  {
    key: 'date',
    dataCell: dataItem => <span>{dataItem.date.toDateString()}</span>,
    width: 120,
    className: 'flex-shrink-0',
    title: 'Date',
  },
  {
    key: 'tip-off',
    className: 'flex-shrink-0',
    dataCell: dataItem => <span>{formatTime(dataItem.date)}</span>,
    width: 80,
    title: 'Tip Off',
    overflowBreakpoint: CSS_BREAKPOINTS.sm,
  },
  {
    className: 'flex-shrink-0 ts-text-overflow',
    key: 'opponent',
    dataCell: dataItem => <span>{dataItem.opponent || '-'}</span>,
    width: 200,
    title: 'Opponent',
    overflowBreakpoint: CSS_BREAKPOINTS.sm,
  },
  {
    key: 'location',
    className: 'flex-shrink-0',
    dataCell: dataItem => <span>{dataItem.venueIsHome ? 'Home' : 'Away'}</span>,
    width: 120,
    title: 'Location',
    overflowBreakpoint: CSS_BREAKPOINTS.md,
  },
  {
    className: 'flex-grow-1 ts-text-overflow',
    key: 'venue',
    dialogDataCell: dataItem => (
      <tr>
        <td className='py-2 pe-3 ts-fw-500 align-middle text-nowrap'>Venue</td>
        <td className='py-2 align-middle'>{dataItem.venue}</td>
      </tr>
    ),
    dataCell: dataItem => (
      <div className='d-flex align-items-center gap-1'>
        <MapPin className='ts-color-primary flex-shrink-0' />
        <span className='ts-text-overflow'>{dataItem.venue || '-'}</span>
      </div>
    ),
    width: 250,
    title: 'Venue',
  },
  {
    className: 'flex-grow-0',
    width: 20,
    key: 'actions',
    dialogDataCell: () => null,
    dataCell: dataItem => <DataTableItemDialog columns={columns} dialogTitle={'Details'} dataItem={dataItem} />,
  },
];

export const FixtureMonthAccordion: React.FC<EventMonthAccordionProps> = ({ month, fixture, isDefaultOpen }) => {
  return (
    <Accordion title={month} isDefaultOpen={isDefaultOpen}>
      <DataTable data={fixture} columns={columns} headerClassName='ts-fw-500 ts-fs-16' />
    </Accordion>
  );
};
