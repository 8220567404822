import { useEffect, useState } from 'react';
import { useClub } from 'src/context/useClub';
import { apiClient } from 'src/utils/apiClient';
import { stringFromJoinedParts } from 'src/utils/strings';

export interface TeamEvent {
  id: number;
  date: Date;
  venue: string;
  result: string;
  opponent: string;
  venueIsHome: boolean;
}

interface ClubTeamsResponse {
  results: {
    created_at: DateTimeString;
    created_by: number;
    event_address1: string;
    event_address2: string | null;
    event_city: string;
    event_coach_1: number | null;
    event_coach_2: number | null;
    event_color: string | null;
    event_competition: string | null;
    event_country: string | null;
    event_country_code: string | null;
    event_description: string;
    event_division_stage: string | null;
    event_door_number: string | null;
    event_end: DateTimeString;
    event_id: number;
    event_is_at_home: 1 | 0;
    event_name: string;
    event_oponent_colour: string | null;
    event_oponent_manager_email: string | null;
    event_oponent_manager_name: string | null;
    event_oponent_manager_phone: string | null;
    event_opponent: string | null;
    event_postcode: string;
    event_recurring: 1 | 0;
    event_recurring_info: string | null;
    event_recurring_until_date: DateTimeString | null;
    event_season: string | null;
    event_start: DateTimeString;
    event_state_province: string | null;
    event_type: string;
    event_venue_gm_zoom: number;
    event_venue_lat: string | null;
    event_venue_lng: string | null;
    event_venue_placename: string;
    flag_player_of_the_match: 1 | 0;
    flag_remind_coach_of_game_attendance: 1 | 0;
    flag_remind_player_to_respond: 1 | 0;
    parent_id: number | null;
    team_id: number;
    team_name: string;
    updated_at: DateTimeString;
  }[];
}

export type TeamFixture = TeamEvent[];

export async function getTeamFixtures(teamId: number, siteCode: string): Promise<TeamFixture> {
  const { results } = await apiClient<ClubTeamsResponse>(`/v1/website/calendar/${teamId}`, { siteCode });

  return results.map<TeamEvent>(event => ({
    date: new Date(event.event_start),
    id: event.event_id,
    opponent: event.event_opponent || 'Unknown',
    result: 'Win',
    venue: stringFromJoinedParts([event.event_address1, event.event_city || '', event.event_postcode]),
    venueIsHome: Boolean(event.event_is_at_home),
  }));
}

export function useTeamFixtures(teamId: number) {
  const { club } = useClub();

  const [fixtures, setFixtures] = useState<TeamFixture | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const result = await getTeamFixtures(teamId, club.info.code);
        setFixtures(result);
      } catch (err) {
        setFixtures(null);
        setError(err as Error);
      }
      setIsLoading(false);
    };
    fetchFixtures();
  }, [club.info.code, teamId]);

  return { fixtures, isLoading, error };
}
