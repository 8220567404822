import { Card } from 'src/shared/widgets/Card';
import { HighlightEditorThumbnail } from './HighlighEditorThumbnail';
import css from './index.module.scss';
import { SimpleGrid } from '@chakra-ui/react';
import { QueryResponse } from './highlightsQuery';

export default function LatestHighights({ highlightQuery }: { highlightQuery: QueryResponse }) {
  console.log('HIGHLIGHTS QUERY', highlightQuery);
  return (
    <Card>
      {highlightQuery.highlights.length ? (
        <div className={css.gallery}>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3 }}
            spacing={{ base: 4, sm: 8, md: 16, lg: 24, xl: 28, '2xl': 32 }}
          >
            {highlightQuery.highlights.map(highlight => (
              <HighlightEditorThumbnail
                className={css.thumbnail}
                highlight={highlight}
                withTagAbbr
                key={highlight.id}
              />
            ))}
          </SimpleGrid>
        </div>
      ) : (
        <h6>No highlights to show</h6>
      )}
    </Card>
  );
}
